import React from 'react'
import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Card } from "primereact/card";

//&emsp;- átvett tanuló esetében 10000ft/óra

function Prices() {
  useEffect(() => {
    document.title = 'Balaton Autósiskola - Árak'
  }, [])

  const services = [
    { title: 'Elméleti oktatás - B', price: '49.000ft', details: 'e-learning kényelmes otthoni saját tempós oktatásban + elsősegély tananyag (e-learning formában)' },
    { title: 'Elméleti oktatás - AM', price: '45.000ft', details: 'e-learning kényelmes otthoni saját tempós oktatásban' },
    { title: 'Elméleti oktatás - A', price: '49.000ft', details: 'e-learning kényelmes otthoni saját tempós oktatásban' },
    { title: 'Elméleti oktatás - A1', price: '49.000ft', details: 'e-learning kényelmes otthoni saját tempós oktatásban' },
    { title: 'Elméleti oktatás - A2', price: '49.000ft', details: 'e-learning kényelmes otthoni saját tempós oktatásban' },
    { title: 'Gyakorlat - AM, A1, A2, A, B', price: '9500ft/óra', details: 'nálunk végzett elméleti oktatás esetében' },
    { title: 'Gyakorlat - AM, A1, A2, A, B', price: '11000ft/óra', details: 'más képzőszervtől átvett tanuló esetében' },
    { title: 'Angol nyelven való oktatás', price: '14000ft/óra' },
    { title: 'Gyorsított (3 hét alatt 30 óra levezetése)', price: '14000ft/óra' },
    { title: 'Elméleti vizsga díj', price: '4600ft' },
    { title: 'Gyakorlati vizsga díj', price: '11000ft' },
  ];

  // return (
  //   <>
  //   <div className='all-prices'>
  //   <div className='title'>
  //     Díjak
  //   </div>
  //   <div className='low-title background-blue'>
  //   <motion.div
  //     initial={{ opacity: 0 }}
  //     animate={{ opacity: 50 }}
  //     transition={{ duration: 3 }}
  //     className='row'>
  //       {services.map((service, i) => (
  //         <div className="col-12 col-sm-6 col-md-4 mb-4" key={i}>
  //           <Card style={{ width: '90%', margin: 'auto' }}>
  //             <Card.Body>
  //               <Card.Title>{service.title}</Card.Title>
  //               <Card.Text>
  //                 {service.details}
  //               </Card.Text>
  //               <h3>{service.price}</h3>
  //             </Card.Body>
  //           </Card>
  //         </div>
  //       ))}
  //     </motion.div><motion.div
  //       initial={{ opacity: 0 }}
  //       animate={{ opacity: 10 }}
  //       transition={{ duration: 8 }}
  //       className='low-title'>
  //     </motion.div><p className='low-titles'>
  //       Bankszámlaszámunk:
  //     </p><p className='low-title-content2'>
  //       &emsp;BALATON AUTÓSISKOLA KFT.<br />
  //       &emsp;<b>12023101 01881804 00100009</b><br />
  //       &emsp;RAIFFEISEN BANK<br />
  //       &emsp;Közleménybe Név + mit utal (pl.: óra mennyisége)
  //     </p><p className='nincs-rejtett'>
  //       Nincs rejtett költség! Nálunk perc alapú vezetés van!
  //     </p>
  //     </div>
  //     </div>
  //     </>

  // )

  return (
    <div className='prices-page'>
      <motion.h1
        className="text-center mb-5"
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        Díjak
      </motion.h1>

      <div className="grid">
        <AnimatePresence>
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="col-12 md:col-6 lg:col-4 mb-4"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, delay: index * 0.05 }}
            >
              <Card
                title={service.title}
                subTitle={service.details}
                className="p-card shadow-sm"
              >
                <h3 className="price-text">{service.price}</h3>
              </Card>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      <motion.div
        className="bank-info mt-5 p-3"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <h4 className="mb-3">Bankszámlaszámunk</h4>
        <p>
          <strong>BALATON AUTÓSISKOLA KFT.</strong>
          <br />
          <strong>12023101 01881804 00100009</strong>
          <br />
          RAIFFEISEN BANK
          <br />
          <em>Közleménybe: Név + mit utal (pl.: óra mennyisége)</em>
        </p>
      </motion.div>

      <motion.div
        className="footer-note text-center mt-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <p className="mb-0">
          Nincs rejtett költség! Nálunk perc alapú vezetés van!
        </p>
      </motion.div>
    </div>
  );
}
export default Prices;
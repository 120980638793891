import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import './carousel.css'

const Carousel = (props) => {
  useEffect(() => {
    new Swiper('.swiper', {
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
      },
    });

    return () => swiper.destroy();
  }, []);

  return (
    <div
      className={`carousel-gallery3 thq-section-padding ${props.rootClassName} `}
    >
      <animate-on-reveal
        animation="fadeIn"
        duration="500ms"
        delay="0s"
        direction="normal"
        easing="ease"
        iteration="1"
        className=""
      >
        <div
          data-thq-animate-on-reveal="true"
          className="carousel-max-width thq-section-max-width"
        >
          <div className="carousel-section-title">
            <span className="carousel-text thq-body-large">
              {props.content1}
            </span>
          </div>
          <div className="carousel-container">
            <div className="carousel-content">
              <div
                data-thq="slider"
                data-loop="true"
                data-autoplay="true"
                data-navigation="true"
                data-pagination="true"
                data-disable-autoplay-on-interaction="true"
                className="carousel-slider swiper"
              >
                <div data-thq="slider-wrapper" className="swiper-wrapper">
                  <div
                    data-thq="slider-slide"
                    className="carousel-slider-slide swiper-slide"
                  >
                    <img
                      alt={props.image1Alt}
                      src={props.image1Src}
                      className="carousel-image thq-img-ratio-4-3"
                    />
                  </div>
                  <div
                    data-thq="slider-slide"
                    className="carousel-slider-slide1 swiper-slide"
                  >
                    <img
                      alt={props.image2Alt}
                      src={props.image2Src}
                      className="carousel-image1 thq-img-ratio-4-3"
                    />
                  </div>
                  <div
                    data-thq="slider-slide"
                    className="carousel-slider-slide2 swiper-slide"
                  >
                    <img
                      alt={props.image3Alt}
                      src={props.image3Src}
                      className="carousel-image2 thq-img-ratio-4-3"
                    />
                  </div>
                  <div
                    data-thq="slider-slide"
                    className="carousel-slider-slide3 swiper-slide"
                  >
                    <img
                      alt={props.image4Alt}
                      src={props.image4Src}
                      className="carousel-image3 thq-img-ratio-4-3"
                    />
                  </div>
                </div>
                <div
                  data-thq="slider-button-prev"
                  className="swiper-button-prev"
                ></div>
                <div
                  data-thq="slider-button-next"
                  className="swiper-button-next"
                ></div>
                <div
                  data-thq="slider-pagination"
                  className="carousel-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
                >
                  <div
                    data-thq="slider-pagination-bullet"
                    className="swiper-pagination-bullet swiper-pagination-bullet-active"
                  ></div>
                  <div
                    data-thq="slider-pagination-bullet"
                    className="swiper-pagination-bullet"
                  ></div>
                  <div
                    data-thq="slider-pagination-bullet"
                    className="swiper-pagination-bullet"
                  ></div>
                  <div
                    data-thq="slider-pagination-bullet"
                    className="swiper-pagination-bullet"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </animate-on-reveal>
    </div>
  )
}

Carousel.defaultProps = {
  rootClassName: '',
  content1:
    'Tekintse meg rövid galériánkat, ahol bemutatjuk autósiskolánkat és oktatóit, valamint a használt járműveket.',
  image1Src: './images/oktatok_uj.JPG',
  image2Src: './images/autok.JPG',
  image3Src: './images/iroda3.JPG',
  image4Src: './images/motorok.jpg',
  image2Alt: 'Autósiskola autói',
  image3Alt: 'Irodánk',
  image4Alt: 'Autósiskola motorjai',
  image1Alt: 'Autósiskola oktatói',
}

Carousel.propTypes = {
  rootClassName: PropTypes.string,
  content1: PropTypes.string,
  image1Src: PropTypes.string,
  image2Src: PropTypes.string,
  image3Src: PropTypes.string,
  image2Alt: PropTypes.string,
  image3Alt: PropTypes.string,
  image1Alt: PropTypes.string,
}

export default Carousel

import React from 'react'
import { useEffect } from 'react';
import BlinkingButton from '../Components/BlinkingButton/BlinkingButton';
import BlinkingButtonEng from '../Components/BlinkingButton/BlinkingButtonEng';
import { AnimatePresence, motion } from 'framer-motion';
import { Card } from "primereact/card";

function ElmeletiKepzes() {
  useEffect(() => {
    document.title = 'Balaton Autósiskola - Elméleti képzés'
  }, []);

  const fadeVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0 }
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="ElmeletiKepzesPage"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={fadeVariants}
        transition={{ duration: 0.7 }}
        className="elmeleti-page"
      >
        <Card className="p-card">
          <h1 className="text-center mb-4">Elméleti képzés</h1>
          <p className="bcategory mb-4">
            Elméletet (Kreszt) nálunk e-learning rendszeren, otthon, saját
            tempóban, számítógépen végezhetik el a tanulók.
          </p>

          <div className="grid">
            <div className="col-12 md:col-6 text-center mb-3">
              <h4 className="mb-2">Jelentkezz online magyarul</h4>
              <BlinkingButton />
            </div>

            <div className="col-12 md:col-6 text-center mb-3">
              <h4 className="mb-2">Apply online in English</h4>
              <BlinkingButtonEng />
            </div>
          </div>
        </Card>
      </motion.div>
    </AnimatePresence>
  );

  // return (
  //   <AnimatePresence mode="wait">
  //     <motion.div
  //       key="homePage"
  //       initial={{ opacity: 0 }}
  //       animate={{ opacity: 1 }}
  //       exit={{ opacity: 0 }}
  //     >
  //       <div className='title'>
  //         Elméleti képzés
  //       </div>
  //       <div className='low-title'>
  //         <div className='bcategory'>
  //           Elméletet (Kreszt) nálunk e-learning rendszeren, otthon saját tempóban számítógépen végezhetik el a tanulók.
  //         </div>
  //         <div className='low-titles-elmeleti'>
  //           Jelentkezz online magyarul:
  //         </div>
  //         <p className="apply-button">
  //           <BlinkingButton />
  //         </p>
  //         <div className='low-titles-elmeleti'>
  //           Apply online in english:
  //         </div>
  //         <p className="apply-button">
  //           <BlinkingButtonEng />
  //         </p>
  //       </div>
  //     </motion.div>
  //   </AnimatePresence>
  // )
}
export default ElmeletiKepzes;
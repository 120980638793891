import React from 'react';
import { useAuth } from './AuthProvider';

const DeleteAccount = () => {
  const { deleteUserAccount, user } = useAuth();

  const handleDelete = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete your account? This action cannot be undone.");
    if (confirmDelete) {
      try {
        await deleteUserAccount();
        alert('Your account has been deleted successfully.');
      } catch (error) {
        console.error('Error deleting user account: ', error);
        alert('There was an error deleting your account. Please try again.');
      }
    }
  };

  return user && <button onClick={handleDelete}>Delete Account</button>
};

export default DeleteAccount;

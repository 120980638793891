import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

const SocialIcons = () => {
  return (
    <div className="social-icons">
      <a href="https://etitan.hu/site/hu/WebReg/IskolaiRegisztracio?azonosito=hzXvSuWYrUC1_6_zjIBazg" target="_blank" rel="noopener noreferrer">
        <button type="button" className="btn btn-dark">Jelentkezz online</button>
      </a>
      <a href="https://www.facebook.com/profile.php?id=100091974471166" target="_blank" rel="noopener noreferrer">
        <FaFacebook size={36} className='facebook' />
      </a>
      <a href="https://instagram.com/balaton_autosiskola?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
        <FaInstagram size={36} className='instagram' />
      </a>
      <a href="https://etitan.hu/site/WebReg/Registration/hzXvSuWYrUC1_6_zjIBazg" target="_blank" rel="noopener noreferrer">
        <button type="button" className="btn btn-dark">Apply for english course</button>
      </a>
    </div>
  );
};

export default SocialIcons;

import React from 'react'
import { useEffect } from 'react';

function Courses() {
  useEffect(() =>{
    document.title = 'Balaton Autósiskola - Képzések'
  }, [])



  return(
   <div className='title'>
    Courses
    </div>
  )
}
export default Courses;
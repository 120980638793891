import React from 'react'
import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { teachersData } from '../Components/TeachersData/teachersData';
import { Card } from "primereact/card";


function Teachers() {
  useEffect(() => {
    document.title = 'Balaton Autósiskola - Oktatók'
  }, []);

  const fadeUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="teachersPage"
        className="teachers-page"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.h1
          className="text-center mb-5"
          variants={fadeUpVariants}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.6 }}
        >
          Oktatóink
        </motion.h1>

        <div className="grid">
          {teachersData.map((teacher, index) => (
            <motion.div
              key={teacher.name + index}
              className="col-12 md:col-6 lg:col-4 mb-4"
              variants={fadeUpVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.6, delay: index * 0.1 }}
            >
              <Card className="teacher-card p-card shadow-sm">
                <div className="teacher-image-container text-center mb-3">
                  <img
                    src={teacher.image}
                    alt={teacher.name}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>

                <h3>{teacher.name}</h3>
                <h5 className="text-muted">{teacher.position}</h5>

                {teacher.instructorId && (
                  <p className="mt-2 mb-1">
                    <strong>Oktató azonosító:</strong> {teacher.instructorId}
                  </p>
                )}
                {teacher.phone && (
                  <p className="mb-3">
                    <strong>Telefonszám:</strong> {teacher.phone}
                  </p>
                )}

                <p>{teacher.bio}</p>

                {teacher.car && (
                  <p className="mt-3">
                    <strong>Jármű:</strong> {teacher.car}
                  </p>
                )}
              </Card>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </AnimatePresence>
  );
  // return (
  //   <AnimatePresence mode="wait">
  //     <motion.div
  //       key="homePage"
  //       initial={{ opacity: 0 }}
  //       animate={{ opacity: 1 }}
  //       exit={{ opacity: 0 }}
  //     >
  //       <div className='title'>
  //         Oktatóink
  //       </div>
  //       <div className='low-title'>
  //         <p className='oktato1'>
  //           -- Szabó Szabolcs --
  //         </p>
  //         <Fade bottom>
  //         <img src='./images/Szabi_profil.jpg' className='oktato1-szabi' alt='' width={350} height={450}/>
  //         <p className='low-title-content2'>Telefonszám: 06302169252</p>
  //         <p className='low-title-content2'>Oktató azonosító: 15697</p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='oktato1-beosztas'>
  //           Balaton autósiskola ügyvezetője, oktatója
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='low-title-content2'>
  //           Ügyvezetőként szeretném az autósiskola fiatalos, lendületes formáját megőrizni.
  //           Nyitottan állok az innovációs és technológiai újítások előtt.<br />
  //           Oktatóként pedig legfőbb célom türelemmel és a legjobb szakmai tudomásomat átadva  végigkísérni a
  //           tanulókat a kezdeti lépésektől a jogosítvány megszerzéséig. Fontosnak tartom, hogy a levizsgázottak magabiztos
  //           tudásra tegyenek szert, és biztonságos közlekedői legyenek az utaknak!
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='oktato1-beosztas'>
  //           Autóm:
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p data-aos="fade-up" className='low-title-content2'>
  //           VW Polo IV Nagyon komfortos jól kezelhető városi autó. Manuális váltóval.
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='oktato2'>
  //           -- Horváth Zsolt --
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <img src='./images/Zsolt2.JPG' className='oktato2-zsolt' alt='' width={350} height={450}/>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='low-title-content2'>Telefonszám: 06706621010</p>
  //         <p className='low-title-content2'>Oktató azonosító: 16087</p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='oktato1-beosztas'>
  //           Balaton autósiskola iskolavezetője, oktatója
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='low-title-content2'>
  //           2017 óta foglalkozom járművezető szakoktatóként.<br />
  //           Fontosnak tartom, hogy tanulóimat változatos oktatási módszerekkel, gyakorlatiasan, türelemmel irányítsam, vezessem az
  //           oktatási folyamat során. Célom, hogy a tanulásra fordított idő maximális kihasználtsággal vezessen a kitűzött célhoz,
  //           a jogosítvány megszerzéséhez.
  //           Ehhez személy és teherszállítási tapasztalataimat is kamatoztatni tudom. Fiatalos lendülettel várom a tanulni vágyókat.
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='oktato1-beosztas'>
  //           Autóm:
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p data-aos="fade-up" className='low-title-content2'>
  //           VW Golf VI dízel üzemű manuális váltóval szerelt jármű.
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='oktato2'>
  //           -- Horváth Dániel --
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <img src='./images/Daniel2.JPG' className='oktato3-daniel' alt='' width={350} height={450} />
  //         </Fade>
  //         <Fade bottom>
  //         <p className='low-title-content2'>Telefonszám: 06302172486</p>
  //         <p className='low-title-content2'>Oktató azonosító: 17262</p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='oktato1-beosztas'>
  //           Oktató
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='low-title-content2'>
  //           Célom, hogy magas színvonalú képzés mellett barátságos környezetben készítsem fel tanulóimat a vezetésre.
  //           A szakmai tudás átadása jó hangulatú, személyre szabott vezetési órákon történik.
  //           Fontosnak tartom, hogy a közös munka során kialakuljon a biztonságérzet, amellyel a jogosítvány
  //           megszerzése után a tanuló nyugodtan vehet részt a közlekedésben.
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='oktato1-beosztas'>
  //           Autóm:
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='low-title-content2'>
  //           Suzuki S-cross (2022), mely számos extrával rendelkezik, amik mind elősegítik a sikeres vizsgát.
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='oktato2'>
  //           -- Helmeci Levente --
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <img src='./images/Levente.jpg' className='oktato4-levente' alt='' width={350} height={450} />
  //         </Fade>
  //         <Fade bottom>
  //         <p className='low-title-content2'>Telefonszám: 06309311519</p>
  //         <p className='low-title-content2'>Oktató azonosító: 15917</p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='oktato1-beosztas'>
  //           Oktató
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='low-title-content2'>
  //           Több évtizedes szakmai tapasztalatommal várom a tanulni vágyókat.
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='oktato1-beosztas'>
  //           Autóm:
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='low-title-content2'>
  //           Citroen Xsara Picasso, Dízel.
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='oktato2'>
  //           -- Tóth László --
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <img src='./images/Toth_Laszlo.jpg' className='oktato4-levente' alt='' width={350} height={450} />
  //         </Fade>
  //         <Fade bottom>
  //         <p className='low-title-content2'>Telefonszám: 06209643642</p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='oktato1-beosztas'>
  //           Motoros oktató
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='low-title-content2'>
  //           Több évtizedes motoros oktatásban eltöltött tapasztalatommal és végtelen türelmemmel várom a motorozni vágyókat!
  //         </p>
  //         </Fade>
  //       </div>
  //     </motion.div>
  //   </AnimatePresence>
  // )
}
export default Teachers;
import React, { useEffect, useState } from 'react';
import { db } from './Firebase';
import { doc, setDoc } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { Container, Row, Col, Alert, ProgressBar } from 'react-bootstrap';

const Fail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [progress, setProgress] = useState(0);
  
    useEffect(() => {
      const failSubscription = async () => {

        const queryParams = new URLSearchParams(location.search);
        const uid = queryParams.get('uid');
        const sessionId = queryParams.get('session_id');
  
        if (uid && sessionId) {
          try {
            await setDoc(doc(db, 'subscriptions', uid), { isSubscribed: false }, { merge: true });
            // console.log("Subscription failed for UID:", uid);
          } catch (error) {
            console.error('Error failing subscription:', error);
          }
        } else {
          console.error('UID or Session ID missing in URL parameters');
        }
      };
  
      failSubscription();

      const countdownInterval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(countdownInterval);
            return 100;
          }
          return prev + 100 / 5;
        });
      }, 1000);

      const timer = setTimeout(() => {
          navigate('/live');
          window.location.reload();
      }, 5000);

      return () => {
        clearTimeout(timer);
        clearInterval(countdownInterval);
      }
    }, [location, user, navigate]);
  
    return (
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <Alert variant="danger" className="p-4">
              <h2 className="mb-3">Előfizetés sikertelen</h2>
              <p>
              A fizetési folyamat sikertelen volt. Kérjük, próbálja meg újra.<br/>
              Az oldal <strong>5 másodpercen belül</strong> átirányít.
              </p>
              <ProgressBar now={progress} label={`${Math.round(progress)}%`} animated />
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  };
  
  export default Fail;
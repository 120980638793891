import React, { useEffect } from "react";
import MyTable from '../Components/MyTable/MyTable';
import { AnimatePresence, motion } from 'framer-motion';
import { Panel } from "primereact/panel";

const data = [
  { szemelyes_adat: "név", adatkezeles_celja: "a szerződés teljesítése érdekében a közúti járművezetői vizsgához a vizsgázó személy 24/2005. (IV.21.) GKM rendelet szerint előírt " },
  { szemelyes_adat: "születési név", adatkezeles_celja: null },
  { szemelyes_adat: "anyja neve", adatkezeles_celja: null },
  { szemelyes_adat: "születési hely és idő", adatkezeles_celja: null },
  { szemelyes_adat: "állampolgárság", adatkezeles_celja: null },
  { szemelyes_adat: "lakcím", adatkezeles_celja: null },
  { szemelyes_adat: "értesítési cím", adatkezeles_celja: null },
  { szemelyes_adat: "telefonszám", adatkezeles_celja: null },
  { szemelyes_adat: "e-mail cím", adatkezeles_celja: null },
  { szemelyes_adat: "személyazonosító okmány adatai", adatkezeles_celja: null },
  { szemelyes_adat: "járművezetői igazolvány adatai", adatkezeles_celja: null },
  { szemelyes_adat: "vezetői engedély adatai", adatkezeles_celja: null },
  { szemelyes_adat: "nyilatkozat alapfokú iskolai végzettségről", adatkezeles_celja: null },
  { szemelyes_adat: "felmentést igazoló okmány adatai", adatkezeles_celja: null },
  { szemelyes_adat: "egészségügyi alkalmasságának adatai", adatkezeles_celja: null },
  { szemelyes_adat: "járművezetéstől eltiltásának adatai", adatkezeles_celja: null },
  { szemelyes_adat: "okmányai visszavonásának adatai", adatkezeles_celja: null },
  { szemelyes_adat: "utánképzési kötelezettségére vonatkozó adatai", adatkezeles_celja: null },
  { szemelyes_adat: "vezetési jártasság igazolására vonatkozó adatai", adatkezeles_celja: null },
  { szemelyes_adat: "tanfolyammentes vizsgára kötelezésének adatai", adatkezeles_celja: null },
  { szemelyes_adat: "kényszergyógyítás vagy kényszergyógykezelés ténye", adatkezeles_celja: null },
  { szemelyes_adat: "soron kívüli egészségi alkalmassági igazolás adatai", adatkezeles_celja: null },
  { szemelyes_adat: "rendkívüli pályaalkalmassági vizsgálatának adatai", adatkezeles_celja: null },
  { szemelyes_adat: "neme", adatkezeles_celja: "A szerződés teljesítése érdekében a Felnőttképzési Adatszolgáltatási Rendszer részére" },
  { szemelyes_adat: "legmagasabb iskolai végzettsége", adatkezeles_celja: null },
  { szemelyes_adat: "oktatási azonosítója", adatkezeles_celja: null },
  { szemelyes_adat: "adóazonosító jele", adatkezeles_celja: null },
  { szemelyes_adat: "gondviselő neve", adatkezeles_celja: "16. életévét be nem töltött gyermek feletti szülői felügyeletet gyakorló személy azonosítása" },
  { szemelyes_adat: "gondviselő telefonszáma", adatkezeles_celja: null },
  { szemelyes_adat: "gondviselő email címe", adatkezeles_celja: null },
]


function DataPolicy() {
  useEffect(() => {
    document.title = 'Balaton Autósiskola - Adatvédelmi tájékoztató'
  }, []);

  const fadeVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0 },
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="dataPolicyPage"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={fadeVariants}
        transition={{ duration: 0.6 }}
        className="data-policy-page"
      >
        <h1 className="dp-title text-center mb-4">ADATKEZELÉSI TÁJÉKOZTATÓ</h1>

        <div className="dp-toc mb-5">
          <h4 className="mb-3">Tartalomjegyzék</h4>
          <ul>
            <li>
              <a href="#adatkezelo">1. Adatkezelő megnevezése</a>
            </li>
            <li>
              <a href="#jogszabalyok">2. Az adatkezelés alapjául szolgáló jogszabályok</a>
            </li>
            <li>
              <a href="#szerzodeses">
                3. A szerződés megkötéséhez, ill. teljesítéséhez kapcsolódó adatkezelés
              </a>
            </li>
            <li>
              <a href="#hozzaferes">
                4. Az adatokhoz való hozzáférés és az adatbiztonsági intézkedések
              </a>
            </li>
            <li>
              <a href="#jogai">5. Az adatkezeléssel kapcsolatos jogai</a>
            </li>
            <li>
              <a href="#jogervenyesites">
                6. Az adatkezeléssel kapcsolatos jogérvényesítési lehetőségek
              </a>
            </li>
            <li>
              <a href="#ertesitesek">7. Értelmező rendelkezések</a>
            </li>
            <li>
              <a href="#egyeb">8. Egyéb</a>
            </li>
          </ul>
        </div>

        {/* 1. Adatkezelő megnevezése */}
        <Panel header="1. Adatkezelő megnevezése" className="dp-panel" id="adatkezelo">
          <p className="dp-content">
            <strong>BALATON AUTÓSISKOLA Kft.</strong>
            <br />
            Székhely: 8600 Siófok, Kristály utca 39.
            <br />
            Postacím: 8600 Siófok, Kálmán Imre Sétány 19.
            <br />
            Telefonszám: +36204038020
            <br />
            E-mail: info@autosiskolabalaton.hu
            <br />
            Honlap:{" "}
            <a href="https://autosiskolabalaton.hu/" target="_blank" rel="noreferrer">
              www.autosiskolabalaton.hu
            </a>
          </p>
        </Panel>

        {/* 2. Az adatkezelés alapjául szolgáló jogszabályok */}
        <Panel
          header="2. Az adatkezelés alapjául szolgáló jogszabályok"
          className="dp-panel"
          id="jogszabalyok"
        >
          <p className="dp-content">
          - az Európai Parlament és a Tanács 2016/679. rendelete (2016. április 27.) a természetes
            személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen
            adatok szabad áramlásáról, valamint a 95/46/EK rendelet hatályon kívül helyezéséről: <br />
            <a href='https://www.naih.hu/files/GDPR_TXT_HU.pdf'>https://www.naih.hu/files/GDPR_TXT_HU.pdf</a> <br />
            - az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi
            CXII. törvény: <br />
            <a href='https://net.jogtar.hu/jr/gen/hjegy_doc.cgi?docid=A1100112.TV'>https://net.jogtar.hu/jr/gen/hjegy_doc.cgi?docid=A1100112.TV</a> <br />
            - az elektronikus hírközlésről szóló 2003. évi C. törvény: <br />
            <a href='https://net.jogtar.hu/jogszabaly?docid=A0300100.TV'>https://net.jogtar.hu/jogszabaly?docid=A0300100.TV</a> <br />
            - a közúti járművezetők és a közúti közlekedési szakemberek képzésének és
            vizsgáztatásának részletes szabályairól szóló 24/2005. (IV. 21.) GKM rendelet: <br />
            <a href='https://net.jogtar.hu/jogszabaly?dbnum=1&docid=A0500024.GKM&mahu=1'>https://net.jogtar.hu/jogszabaly?dbnum=1&docid=A0500024.GKM&mahu=1</a> <br />
            - a számvitelről szóló 2000. évi C. törvény: <br />
            <a href='https://net.jogtar.hu/jogszabaly?docid=A0000100.TV'>https://net.jogtar.hu/jogszabaly?docid=A0000100.TV</a> <br />
            - a felnőttképzésről szóló 2013. évi LXXVII. törvény: <br />
            <a href='https://net.jogtar.hu/jogszabaly?docid=a1300077.tv'>https://net.jogtar.hu/jogszabaly?docid=a1300077.tv</a>
          </p>
        </Panel>

        {/* 3. Szerződés megkötéséhez kapcsolódó adatkezelés */}
        <Panel
          header="3. A szerződés megkötéséhez, illetve teljesítéséhez kapcsolódó adatkezelés"
          className="dp-panel"
          id="szerzodeses"
        >
          <p className="dp-content">
            3.1. A kezelt adatok köre és az adatkezelés célja
          </p>
          <MyTable data={data} />

          <p className="dp-content mt-3">
            3.2. Az adatkezelés jogalapja:
            <br />
            Az adatkezelés olyan szerződés teljesítéséhez szükséges, amelyben Ön az egyik fél, illetve az
            a szerződés megkötését megelőzően az Ön kérésére történő lépések megtételéhez szükséges.<br />
            <br />
            Az Adatkezelő tájékoztatja Önt arról, hogy a személyes adat szolgáltatása szerződéses
            kötelezettségen alapul és a szerződés kötésének előfeltétele. Amennyiben Ön személyes
            adatainak kezeléséhez nem járul hozzá, illetve nem teljesíti az adatszolgáltatást, úgy a szerződés
            nem jön létre. <br />
            <br />
            3.3. Az adatkezelés időtartama:
            <br/>
            Az Adatkezelő a személyes adatait a szerződésből fakadó követelések érvényesíthetőségének
            elévüléséig, valamint a kapcsolódó jogszabályok által előírt időtartamig, de legalább a
            számvitelről szóló 2000. évi C. törvény számviteli bizonylatok megőrzési kötelezettségére
            vonatkozó rendelkezések szerinti időtartamig, azaz 8 évig kezeli.
          </p>
        </Panel>

        {/* 4. Az adatokhoz való hozzáférés... */}
        <Panel
          header="4. Az adatokhoz való hozzáférés és az adatbiztonsági intézkedések"
          className="dp-panel"
          id="hozzaferes"
        >
          <p className="dp-content">
            4.1. Az adatokhoz való hozzáférés és az adattovábbítás
            <br />
            Az Ön által megadott személyes adatokhoz az Adatkezelő férhet hozzá annak érdekében, hogy
            az Adatkezelő teljesítse az Ön és az Adatkezelő között létrejött szerződést.
            <br />
            <br />
            Az Adatkezelő adatfeldolgozókat vesz igénybe egyes adatkezelési műveletek elvégzéséhez.
            Az E-Educatio Zártkörűen Működő Részvénytársaság (székhely: 1111 Budapest, Budafoki út 59.), mint
            adatfeldolgozó biztosítja az e-Titán Rendszert, melyben az Ön személyes adatai
            rögzítésre kerülnek. Az Adatkezelővel megbízási jogviszonyban álló Ügynökök, valamint
            Szakoktatók, mint adatfeldolgozók a személyes adatok gyűjtését és rögzítését végezhetik. <br />
            <br />
            Az e-Titán Rendszer az e-learning rendszerű elméleti képzés megkezdésekor, illetve a képzés
            befejezésekor továbbítja a vizsgaközpont részére a közúti járművezetők és közúti közlekedési
            szakemberek képzésének és vizsgáztatásának részletes szabályairól szóló 24/2005. (IV.21.)
            GKM rendeletben meghatározott adatokat. Az Adatkezelő a Szakoktató kiválasztását követően
            továbbítja az Ön adatait a vele megbízási jogviszonyban álló Szakoktatónak a gyakorlati képzés
            megkezdése érdekében.<br />
            <br />
            A képzési szerződés létrejöttét követően az adatkezelő a 2013. évi LXXVII. törvényben
            meghatározott személyes adatait továbbítja a Felnőttképzési Adatszolgáltatási Rendszerbe.<br />
            <br />
            Előfordulhat továbbá, hogy az Adatkezelőnek jogszabályi előírás alapján hatóságok vagy
            kormányzati szervek részére át kell adnia az Ön személyes adatait. Átadhatunk továbbá Önnel
            kapcsolatos személyes adatokat akkor is, ha ezt nemzetbiztonsági, jogérvényesítési vagy egyéb
            közérdekű szempontból szükséges. Az Adatkezelő a hatóságok részére – amennyiben a hatóság
            a pontos célt és az adatok körét megjelölte – személyes adatot csak annyit és olyan mértékben
            ad ki, amely a megkeresés céljának megvalósításához elengedhetetlenül szükséges.<br />
            <br />
            4.2. Adatbiztonsági intézkedések
            <br/>
            A tényleges adatkezelés az e-Titán Rendszerben történik. Az Adatkezelő megfelelő
            intézkedésekkel gondoskodik arról, hogy a személyes adatát védje többek között a jogosulatlan
            hozzáférés ellen vagy azok jogosulatlan megváltoztatása ellen. Így például a hozzáférés célhoz
            kötött, a hozzáféréshez jogosultak köre korlátozott és pontosan nyilvántartott, valamint az
            Adatkezelő a személyes adatok kezelése során a hatályos jogszabályoknak megfelelő, az
            adatkezelés menetét részletesen előíró belső szabályzata alapján jár el.
          </p>
        </Panel>

        {/* 5. Az adatkezeléssel kapcsolatos jogai */}
        <Panel header="5. Az adatkezeléssel kapcsolatos jogai" className="dp-panel" id="jogai">
          <p className="dp-content">
            5.1. A tájékoztatás kéréséhez (hozzáféréshez) való jog
            <br />
            Ön az 1.1 pontban megadott elérhetőségeken keresztül tájékoztatást kérhet az alábbiakról: <br />
            - az Adatkezelő milyen személyes adatait, milyen jogalapon, milyen adatkezelési cél miatt,
            milyen forrásból és mennyi ideig kezeli, <br />
            - az Adatkezelő kinek, mikor, milyen jogszabály alapján, mely személyes adataihoz biztosított
            hozzáférést vagy kinek továbbította a személyes adatait. <br />
            Az Adatkezelő az Ön kérelmét indokolatlan késedelem nélkül, az Ön által megadott
            elérhetőségre küldött levélben teljesíti. <br />
            <br />
            5.2. A helyesbítéshez való jog
            <br />
            Ön az 1.1 pontban megadott elérhetőségeken, valamint az e-Titán Rendszeren belül biztosított
            felületeken keresztül kérheti, hogy az Adatkezelő helyesbítse személyes adatait, továbbá
            önállóan módosíthatja megadott e-mail címét. Az Adatkezelő a kérelmét indokolatlan késedelem nélkül teljesíti, és
            erről az Ön által megadott elérhetőségre küldött levélben, vagy a
            bejelentés helyén értesíti. <br />
            <br />
            5.3. A törléshez való jog
            <br />
            Ön az 1.1 pontban megadott elérhetőségeken keresztül kérheti az Adatkezelőtől a személyes
            adatainak a törlését, ha az alábbi indokok valamelyike fennáll: <br />
            &emsp;- a személyes adatokra már nincs szükség abból a célból, amelyből azokat gyűjtötték vagy
            más módon kezelték; <br />
            &emsp;- Ön visszavonja hozzájárulását, és az adatkezelésnek nincs más jogalapja; <br />
            &emsp;- Ön tiltakozik, és nincs elsőbbséget élvező jogszerű ok az adatkezelésre; <br />
            &emsp;- az Ön személyes adatait jogellenesen kezelték; <br />
            &emsp;- a személyes adatokat az Adatkezelőre alkalmazandó uniós vagy tagállami jogban előírt
            jogi kötelezettség teljesítéséhez törölni kell; <br />
            &emsp;- a személyes adatok gyűjtésére az információs társadalommal összefüggő szolgáltatások
            kínálásával kapcsolatosan került sor. <br />
            A törlési kérelmet az Adatkezelő indokolatlan késedelem nélkül teljesíti, továbbá a törlési
            kérelem teljesítéséről vagy a törlési kérelem jogszerű indokon alapuló megtagadásáról az Ön
            által megadott elérhetőségen értesíti.<br />
            <br />
            5.4. Az adatkezelés korlátozásához való jog
            <br />
            Az Ön 1.1 pontban megadott elérhetőségeken keresztül benyújtott kérésére az Adatkezelő
            korlátozza az adatkezelést az alábbiak valamelyikének teljesülése esetén: <br />
            &emsp;- ha Ön vitatja a személyes adatok pontosságát, ez esetben a korlátozás arra az időtartamra
            vonatkozhat, amely lehetővé teszi, hogy az Adatkezelő &nbsp;&nbsp;&nbsp;&nbsp;ellenőrizze a személyes adatok
            pontosságát; <br />
            &emsp;- az adatkezelés jogellenes, és Ön ellenzi az adatok törlését, ehelyett kéri azok
            felhasználásának korlátozását; <br />
            &emsp;- az Adatkezelőnek már nincs szüksége a személyes adatokra adatkezelés céljából, de Ön
            igényli azokat jogi igények előterjesztéséhez, érvényesítéséhez &nbsp;&nbsp;&nbsp;&nbsp;vagy védelméhez; <br />
            &emsp;- Ön tiltakozott az adatkezelés ellen; ez esetben a korlátozás arra az időtartamra
            vonatkozhat, amíg megállapításra nem kerül, hogy az Adatkezelő jogos indokai
            elsőbbséget élveznek-e az Ön jogos indokaival szemben. <br />
            Az Adatkezelő a korlátozott személyes adatokat a tárolás kivételével csak az érintett
            hozzájárulásával, vagy jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez, vagy
            más természetes vagy jogi személy jogainak védelme érdekében, vagy az unió, illetve valamely
            tagállam fontos közérdekéből kezelheti. <br />
            Az Adatkezelő Önt a korlátozás feloldásáról köteles előzetesen tájékoztatni.<br />
            <br />
            5.5. A tiltakozáshoz való jog
            <br />
            Ön az 1.1 pontban megadott elérhetőségeken keresztül tiltakozhat az adatkezelés ellen, ha az
            Adatkezelő a személyes adatait <br />
            &emsp;- közvetlen üzletszerzés, <br />
            &emsp;- az adatkezelés közérdekű vagy az adatkezelőre ruházott közhatalmi jogosítvány
            gyakorlásának keretében végzett feladat végrehajtásához, <br />
            &emsp;- az Adatkezelő vagy egy harmadik fél jogos érdekeinek érvényesítése céljából, <br />
            &emsp;- tudományos és történelmi kutatási célból vagy statisztikai célból kezeli.<br />
            Tiltakozás esetén, ha az a közvetlen üzletszerzésre vonatkozik, úgy az Adatkezelő a
            továbbiakban közvetlen üzletszerzés érdekében az Ön személyes adatait tovább nem kezeli.
            Egyéb esetben az Adatkezelő a tiltakozást indokolatlan késedelem nélkül megvizsgálja és
            annak megalapozottságáról döntést hoz, a döntésről pedig Önt írásban tájékoztatja. <br />
             <br />
            5.6. Az adathordozhatósághoz való jog
            <br/>
            Ön jogosult arra, hogy az Ön által az Adatkezelő rendelkezésére bocsátott személyes adatokat
            tagolt, széles körben használt, géppel olvasható formátumban megkapja, valamint jogosult arra
            is, hogy – ha ez technikailag megvalósítható – kérje az Adatkezelőtől személyes adatainak egy
            másik adatkezelőhöz továbbítását.
          </p>
        </Panel>

        {/* 6. Az adatkezeléssel kapcsolatos jogérvényesítési lehetőségek */}
        <Panel
          header="6. Az adatkezeléssel kapcsolatos jogérvényesítési lehetőségek"
          className="dp-panel"
          id="jogervenyesites"
        >
          <p className="dp-content">
          Az  Ön  által  tapasztalt  jogellenes  adatkezelés  esetén  kérjük,  hogy  először  vegye  fel  a
          kapcsolatot  az  Adatkezelővel  az  1.1  pontban  megadott  elérhetőségeken  keresztül,  a
          helyzet békés, megegyezésen alapuló rendezése érdekében. Amennyiben a helyzet nem
          kerül  rendezésre  tárgyalásos  úton  vagy  nem  kívánja  ezt  a  módot  igénybe  venni,  úgy  a
          következő jogérvényesítési lehetőségei vannak: <br />
          &emsp;- A Nemzeti Adatvédelmi és Információszabadság Hatósághoz (NAIH) fordulhat, az
          alábbi elérhetőségeken: <br />
          &emsp;Székhely: 1055 Budapest, Falk Miksa utca 9-11. <br />
          &emsp;Levelezési cím: 1363 Budapest, Pf.: 9. <br />
          &emsp;Telefon: +36 1 391-1400<br />
          &emsp;+36 (30) 683-5969 <br />
          &emsp;+36 (30) 549-6838 <br />
          &emsp;Fax: +36 (1) 391 1410 <br />
          &emsp;E-mail: ugyfelszolgalat@naih.hu<br />
          &emsp;Honlap: <a href='http://www.naih.hu'>http://www.naih.hu</a> <br />
          <br />
          &emsp;- Az Ön által tapasztalt jogellenes adatkezelés esetén polgári pert kezdeményezhet az
          Adatkezelő ellen. A per elbírálása a törvényszék hatáskörébe tartozik. &nbsp;&nbsp;&nbsp;&nbsp;A per – az Ön
          választása szerint – a lakóhelye szerinti törvényszék előtt is megindítható (a
          törvényszékek felsorolását és elérhetőségét az alábbi linken &nbsp;&nbsp;&nbsp;&nbsp;keresztül tekintheti meg: <a href='http://birosag.hu/torvenyszekek'>http://birosag.hu/torvenyszekek</a>).
          </p>
        </Panel>

        {/* 7. Értelmező rendelkezések */}
        <Panel header="7. Értelmező rendelkezések" className="dp-panel" id="ertesitesek">
          <p className="dp-content">
          &emsp;- <b>Személyes adat:</b> az Önnel kapcsolatba hozható adat, így például az Ön neve, email
          címe, arcképe, hangja, azonosító jele, valamint egy vagy több fizikai, &nbsp;&nbsp;&nbsp;&nbsp;fiziológiai,
          mentális gazdasági, kulturális vagy szociális azonosságára jellemző ismeret, valamint
          az ezekből levonható következtetés. <br />
          &emsp;- <b>Hozzájárulás:</b> az érintett akaratának önkéntes és határozott kinyilvánítása, amely
          megfelelő tájékoztatáson alapul és amellyel félreérthetetlen &nbsp;&nbsp;&nbsp;&nbsp;beleegyezését adja a rá
          vonatkozó személyes adatok – teljes körű vagy egyes műveletekre kiterjedő –
          kezeléséhez.<br />
          &emsp;- <b>Adatkezelő:</b> az a természetes vagy jogi személy (pl. korlátolt felelősségű társaság),
          illetve jogi személyiséggel nem rendelkező szervezet (pl. egyesület), &nbsp;&nbsp;&nbsp;&nbsp;amely
          meghatározza az adatok kezelésének célját, illetve meghozza és végrehajtja vagy
          végrehajtatja az adatkezelésre vonatkozó döntéseket.<br />
          &emsp;- <b>Adatkezelés:</b> az adatokon végzett művelet, így például a személyes adatok gyűjtése,
          felvétele, rögzítése, rendszerezése, tárolása, megváltoztatása, &nbsp;&nbsp;&nbsp;&nbsp;felhasználása,
          lekérdezése, továbbítása, törlése, megsemmisítése, kép- vagy hangfelvétel készítése stb.<br />
          &emsp;- <b>Adattovábbítás:</b> az adat meghatározott harmadik személy számára történő
          hozzáférhetővé tétele.<br />
          &emsp;- <b>Adatfeldolgozás:</b> az adatkezelési műveletekhez kapcsolódó technikai feladatok
          elvégzése, így például az adatok tárolásának biztosítása, könyvelési &nbsp;&nbsp;&nbsp;&nbsp;feladatok ellátása.<br />
          &emsp;- <b>Adattörlés:</b> az adatok felismerhetetlenné tétele oly módon, hogy a helyreállításuk többé
          nem lehetséges.
          </p>
        </Panel>

        {/* 8. Egyéb */}
        <Panel header="8. Egyéb" className="dp-panel" id="egyeb">
          <p className="dp-content">
            8.1. Jelen adatkezelési tájékoztató Siófok, 2023.06.22-én lép hatályba.
          </p>
        </Panel>
      </motion.div>
    </AnimatePresence>
  );

  // return (
  //   <AnimatePresence mode="wait">
  //     <motion.div
  //       key="homePage"
  //       initial={{ opacity: 0 }}
  //       animate={{ opacity: 1 }}
  //       exit={{ opacity: 0 }}
  //     >
  //       <div className='title'>
  //         ADATKEZELÉSI TÁJÉKOZTATÓ
  //       </div>
  //       <div className='low-title'>
  //         <p className='low-title-content1'>
  //           A Balaton Autósiskola által történő adatkezelésekhez
  //         </p>
  //       </div>
  //       <div className='low-title'>
  //         <p className='low-titles'>
  //           1. Adatkezelő megnevezése
  //         </p>
  //         <p className='low-titles2'>
  //           BALATON AUTÓSISKOLA Kft.
  //         </p>
  //         <p className='low-title-content2'>
  //           1.1 Adatkezelő elérhetőségei: <br />
  //           &emsp;Székhely: 8600 Siófok, Kristály utca 39. <br />
  //           &emsp;Postacím: 8600 Siófok, Kálmán Imre Sétány 19. <br />
  //           &emsp;Telefonszám: +36204038020 <br />
  //           &emsp;E-mail: info@autosiskolabalaton.hu <br />

  //           1.2 Adatkezelő honlapja: <a href='https://autosiskolabalaton.hu/'>www.autosiskolabalaton.hu</a>
  //         </p>
  //       </div>
  //       <div className='low-title'>
  //         <p className='low-titles'>
  //           2. Az adatkezelés alapjául szolgáló jogszabályok
  //         </p>
  //         <p className='low-title-content2'>
  //           - az Európai Parlament és a Tanács 2016/679. rendelete (2016. április 27.) a természetes
  //           személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen
  //           adatok szabad áramlásáról, valamint a 95/46/EK rendelet hatályon kívül helyezéséről: <br />
  //           <a href='https://www.naih.hu/files/GDPR_TXT_HU.pdf'>https://www.naih.hu/files/GDPR_TXT_HU.pdf</a> <br />
  //           - az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi
  //           CXII. törvény: <br />
  //           <a href='https://net.jogtar.hu/jr/gen/hjegy_doc.cgi?docid=A1100112.TV'>https://net.jogtar.hu/jr/gen/hjegy_doc.cgi?docid=A1100112.TV</a> <br />
  //           - az elektronikus hírközlésről szóló 2003. évi C. törvény: <br />
  //           <a href='https://net.jogtar.hu/jogszabaly?docid=A0300100.TV'>https://net.jogtar.hu/jogszabaly?docid=A0300100.TV</a> <br />
  //           - a közúti járművezetők és a közúti közlekedési szakemberek képzésének és
  //           vizsgáztatásának részletes szabályairól szóló 24/2005. (IV. 21.) GKM rendelet: <br />
  //           <a href='https://net.jogtar.hu/jogszabaly?dbnum=1&docid=A0500024.GKM&mahu=1'>https://net.jogtar.hu/jogszabaly?dbnum=1&docid=A0500024.GKM&mahu=1</a> <br />
  //           - a számvitelről szóló 2000. évi C. törvény: <br />
  //           <a href='https://net.jogtar.hu/jogszabaly?docid=A0000100.TV'>https://net.jogtar.hu/jogszabaly?docid=A0000100.TV</a> <br />
  //           - a felnőttképzésről szóló 2013. évi LXXVII. törvény: <br />
  //           <a href='https://net.jogtar.hu/jogszabaly?docid=a1300077.tv'>https://net.jogtar.hu/jogszabaly?docid=a1300077.tv</a>

  //         </p>
  //       </div>
  //       <div className='low-title'>
  //       <Fade bottom>
  //         <p className='low-titles'>
  //           3. A szerződés megkötéséhez, illetve teljesítéséhez kapcsolódó adatkezelés
  //         </p>
  //         <p className='low-title-content2'>
  //           3.1. A kezelt adatok köre és az adatkezelés célja

  //           <MyTable data={data} />
  //           <br />

  //           3.2. Az adatkezelés jogalapja <br />
  //           Az adatkezelés olyan szerződés teljesítéséhez szükséges, amelyben Ön az egyik fél, illetve az
  //           a szerződés megkötését megelőzően az Ön kérésére történő lépések megtételéhez szükséges.<br />
  //           <br />
  //           Az Adatkezelő tájékoztatja Önt arról, hogy a személyes adat szolgáltatása szerződéses
  //           kötelezettségen alapul és a szerződés kötésének előfeltétele. Amennyiben Ön személyes
  //           adatainak kezeléséhez nem járul hozzá, illetve nem teljesíti az adatszolgáltatást, úgy a szerződés
  //           nem jön létre. <br />
  //           <br />
  //           3.3. Az adatkezelés időtartama <br />
  //           Az Adatkezelő a személyes adatait a szerződésből fakadó követelések érvényesíthetőségének
  //           elévüléséig, valamint a kapcsolódó jogszabályok által előírt időtartamig, de legalább a
  //           számvitelről szóló 2000. évi C. törvény számviteli bizonylatok megőrzési kötelezettségére
  //           vonatkozó rendelkezések szerinti időtartamig, azaz 8 évig kezeli.
  //         </p>
  //         </Fade>
  //       </div>
  //       <div className='low-title'>
  //       <Fade bottom>
  //         <p className='low-titles'>
  //           4. Az adatokhoz való hozzáférés és az adatbiztonsági intézkedések
  //         </p>
  //         <p className='low-title-content2'>
  //           4.1. Az adatokhoz való hozzáférés és az adattovábbítás <br />
  //           Az Ön által megadott személyes adatokhoz az Adatkezelő férhet hozzá annak érdekében, hogy
  //           az Adatkezelő teljesítse az Ön és az Adatkezelő között létrejött szerződést.
  //           <br />
  //           <br />
  //           Az Adatkezelő adatfeldolgozókat vesz igénybe egyes adatkezelési műveletek elvégzéséhez.
  //           Az E-Educatio Zártkörűen Működő Részvénytársaság (székhely: 1111 Budapest, Budafoki út 59.), mint
  //           adatfeldolgozó biztosítja az e-Titán Rendszert, melyben az Ön személyes adatai
  //           rögzítésre kerülnek. Az Adatkezelővel megbízási jogviszonyban álló Ügynökök, valamint
  //           Szakoktatók, mint adatfeldolgozók a személyes adatok gyűjtését és rögzítését végezhetik. <br />
  //           <br />
  //           Az e-Titán Rendszer az e-learning rendszerű elméleti képzés megkezdésekor, illetve a képzés
  //           befejezésekor továbbítja a vizsgaközpont részére a közúti járművezetők és közúti közlekedési
  //           szakemberek képzésének és vizsgáztatásának részletes szabályairól szóló 24/2005. (IV.21.)
  //           GKM rendeletben meghatározott adatokat. Az Adatkezelő a Szakoktató kiválasztását követően
  //           továbbítja az Ön adatait a vele megbízási jogviszonyban álló Szakoktatónak a gyakorlati képzés
  //           megkezdése érdekében.<br />
  //           <br />
  //           A képzési szerződés létrejöttét követően az adatkezelő a 2013. évi LXXVII. törvényben
  //           meghatározott személyes adatait továbbítja a Felnőttképzési Adatszolgáltatási Rendszerbe.<br />
  //           <br />
  //           Előfordulhat továbbá, hogy az Adatkezelőnek jogszabályi előírás alapján hatóságok vagy
  //           kormányzati szervek részére át kell adnia az Ön személyes adatait. Átadhatunk továbbá Önnel
  //           kapcsolatos személyes adatokat akkor is, ha ezt nemzetbiztonsági, jogérvényesítési vagy egyéb
  //           közérdekű szempontból szükséges. Az Adatkezelő a hatóságok részére – amennyiben a hatóság
  //           a pontos célt és az adatok körét megjelölte – személyes adatot csak annyit és olyan mértékben
  //           ad ki, amely a megkeresés céljának megvalósításához elengedhetetlenül szükséges.<br />
  //           <br />
  //           4.2. Adatbiztonsági intézkedések <br />
  //           A tényleges adatkezelés az e-Titán Rendszerben történik. Az Adatkezelő megfelelő
  //           intézkedésekkel gondoskodik arról, hogy a személyes adatát védje többek között a jogosulatlan
  //           hozzáférés ellen vagy azok jogosulatlan megváltoztatása ellen. Így például a hozzáférés célhoz
  //           kötött, a hozzáféréshez jogosultak köre korlátozott és pontosan nyilvántartott, valamint az
  //           Adatkezelő a személyes adatok kezelése során a hatályos jogszabályoknak megfelelő, az
  //           adatkezelés menetét részletesen előíró belső szabályzata alapján jár el.

  //         </p>
  //         </Fade>
  //       </div>
  //       <div className='low-title'>
  //       <Fade bottom>
  //         <p className='low-titles'>
  //           5. Az adatkezeléssel kapcsolatos jogai
  //         </p>
  //         <p className='low-title-content2'>
  //           5.1. A tájékoztatás kéréshez (hozzáféréshez) való jog <br />
  //           Ön az 1.1 pontban megadott elérhetőségeken keresztül tájékoztatást kérhet az alábbiakról: <br />
  //           - az Adatkezelő milyen személyes adatait, milyen jogalapon, milyen adatkezelési cél miatt,
  //           milyen forrásból és mennyi ideig kezeli, <br />
  //           - az Adatkezelő kinek, mikor, milyen jogszabály alapján, mely személyes adataihoz biztosított
  //           hozzáférést vagy kinek továbbította a személyes adatait. <br />
  //           Az Adatkezelő az Ön kérelmét indokolatlan késedelem nélkül, az Ön által megadott
  //           elérhetőségre küldött levélben teljesíti. <br />
  //           <br />
  //           5.2. A helyesbítéshez való jog <br />
  //           Ön az 1.1 pontban megadott elérhetőségeken, valamint az e-Titán Rendszeren belül biztosított
  //           felületeken keresztül kérheti, hogy az Adatkezelő helyesbítse személyes adatait, továbbá
  //           önállóan módosíthatja megadott e-mail címét. Az Adatkezelő a kérelmét indokolatlan késedelem nélkül teljesíti, és
  //           erről az Ön által megadott elérhetőségre küldött levélben, vagy a
  //           bejelentés helyén értesíti. <br />
  //           <br />
  //           5.3. A törléshez való jog <br />

  //           Ön az 1.1 pontban megadott elérhetőségeken keresztül kérheti az Adatkezelőtől a személyes
  //           adatainak a törlését, ha az alábbi indokok valamelyike fennáll: <br />
  //           &emsp;- a személyes adatokra már nincs szükség abból a célból, amelyből azokat gyűjtötték vagy
  //           más módon kezelték; <br />
  //           &emsp;- Ön visszavonja hozzájárulását, és az adatkezelésnek nincs más jogalapja; <br />
  //           &emsp;- Ön tiltakozik, és nincs elsőbbséget élvező jogszerű ok az adatkezelésre; <br />
  //           &emsp;- az Ön személyes adatait jogellenesen kezelték; <br />
  //           &emsp;- a személyes adatokat az Adatkezelőre alkalmazandó uniós vagy tagállami jogban előírt
  //           jogi kötelezettség teljesítéséhez törölni kell; <br />
  //           &emsp;- a személyes adatok gyűjtésére az információs társadalommal összefüggő szolgáltatások
  //           kínálásával kapcsolatosan került sor. <br />
  //           A törlési kérelmet az Adatkezelő indokolatlan késedelem nélkül teljesíti, továbbá a törlési
  //           kérelem teljesítéséről vagy a törlési kérelem jogszerű indokon alapuló megtagadásáról az Ön
  //           által megadott elérhetőségen értesíti.<br />
  //           <br />

  //           5.4. Az adatkezelés korlátozásához való jog <br />
  //           Az Ön 1.1 pontban megadott elérhetőségeken keresztül benyújtott kérésére az Adatkezelő
  //           korlátozza az adatkezelést az alábbiak valamelyikének teljesülése esetén: <br />
  //           &emsp;- ha Ön vitatja a személyes adatok pontosságát, ez esetben a korlátozás arra az időtartamra
  //           vonatkozhat, amely lehetővé teszi, hogy az Adatkezelő &nbsp;&nbsp;&nbsp;&nbsp;ellenőrizze a személyes adatok
  //           pontosságát; <br />
  //           &emsp;- az adatkezelés jogellenes, és Ön ellenzi az adatok törlését, ehelyett kéri azok
  //           felhasználásának korlátozását; <br />
  //           &emsp;- az Adatkezelőnek már nincs szüksége a személyes adatokra adatkezelés céljából, de Ön
  //           igényli azokat jogi igények előterjesztéséhez, érvényesítéséhez &nbsp;&nbsp;&nbsp;&nbsp;vagy védelméhez; <br />
  //           &emsp;- Ön tiltakozott az adatkezelés ellen; ez esetben a korlátozás arra az időtartamra
  //           vonatkozhat, amíg megállapításra nem kerül, hogy az Adatkezelő jogos indokai
  //           elsőbbséget élveznek-e az Ön jogos indokaival szemben. <br />
  //           Az Adatkezelő a korlátozott személyes adatokat a tárolás kivételével csak az érintett
  //           hozzájárulásával, vagy jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez, vagy
  //           más természetes vagy jogi személy jogainak védelme érdekében, vagy az unió, illetve valamely
  //           tagállam fontos közérdekéből kezelheti. <br />
  //           Az Adatkezelő Önt a korlátozás feloldásáról köteles előzetesen tájékoztatni.<br />
  //           <br />
  //           5.5. A tiltakozáshoz való jog <br />
  //           Ön az 1.1 pontban megadott elérhetőségeken keresztül tiltakozhat az adatkezelés ellen, ha az
  //           Adatkezelő a személyes adatait <br />
  //           &emsp;- közvetlen üzletszerzés, <br />
  //           &emsp;- az adatkezelés közérdekű vagy az adatkezelőre ruházott közhatalmi jogosítvány
  //           gyakorlásának keretében végzett feladat végrehajtásához, <br />
  //           &emsp;- az Adatkezelő vagy egy harmadik fél jogos érdekeinek érvényesítése céljából, <br />
  //           &emsp;- tudományos és történelmi kutatási célból vagy statisztikai célból kezeli.<br />
  //           Tiltakozás esetén, ha az a közvetlen üzletszerzésre vonatkozik, úgy az Adatkezelő a
  //           továbbiakban közvetlen üzletszerzés érdekében az Ön személyes adatait tovább nem kezeli.
  //           Egyéb esetben az Adatkezelő a tiltakozást indokolatlan késedelem nélkül megvizsgálja és
  //           annak megalapozottságáról döntést hoz, a döntésről pedig Önt írásban tájékoztatja. <br />
  //           <br />
  //           5.6. Az adathordozhatósághoz való jog <br />
  //           Ön jogosult arra, hogy az Ön által az Adatkezelő rendelkezésére bocsátott személyes adatokat
  //           tagolt, széles körben használt, géppel olvasható formátumban megkapja, valamint jogosult arra
  //           is, hogy – ha ez technikailag megvalósítható – kérje az Adatkezelőtől személyes adatainak egy
  //           másik adatkezelőhöz továbbítását.
  //         </p>
  //         </Fade>
  //       </div>
  //       <div className='low-title'>
  //       <Fade bottom>
  //         <p className='low-titles'>
  //           6. Az adatkezeléssel kapcsolatos jogérvényesítési lehetőségek
  //         </p>
  //         <p className='low-title-content2'>
  //           Az  Ön  által  tapasztalt  jogellenes  adatkezelés  esetén  kérjük,  hogy  először  vegye  fel  a
  //           kapcsolatot  az  Adatkezelővel  az  1.1  pontban  megadott  elérhetőségeken  keresztül,  a
  //           helyzet békés, megegyezésen alapuló rendezése érdekében. Amennyiben a helyzet nem
  //           kerül  rendezésre  tárgyalásos  úton  vagy  nem  kívánja  ezt  a  módot  igénybe  venni,  úgy  a
  //           következő jogérvényesítési lehetőségei vannak: <br />
  //           &emsp;- A Nemzeti Adatvédelmi és Információszabadság Hatósághoz (NAIH) fordulhat, az
  //           alábbi elérhetőségeken: <br />
  //           &emsp;Székhely: 1055 Budapest, Falk Miksa utca 9-11. <br />
  //           &emsp;Levelezési cím: 1363 Budapest, Pf.: 9. <br />
  //           &emsp;Telefon: +36 1 391-1400<br />
  //           &emsp;+36 (30) 683-5969 <br />
  //           &emsp;+36 (30) 549-6838 <br />
  //           &emsp;Fax: +36 (1) 391 1410 <br />
  //           &emsp;E-mail: ugyfelszolgalat@naih.hu<br />
  //           &emsp;Honlap: <a href='http://www.naih.hu'>http://www.naih.hu</a> <br />
  //           <br />

  //           &emsp;- Az Ön által tapasztalt jogellenes adatkezelés esetén polgári pert kezdeményezhet az
  //           Adatkezelő ellen. A per elbírálása a törvényszék hatáskörébe tartozik. &nbsp;&nbsp;&nbsp;&nbsp;A per – az Ön
  //           választása szerint – a lakóhelye szerinti törvényszék előtt is megindítható (a
  //           törvényszékek felsorolását és elérhetőségét az alábbi linken &nbsp;&nbsp;&nbsp;&nbsp;keresztül tekintheti meg: <a href='http://birosag.hu/torvenyszekek'>http://birosag.hu/torvenyszekek</a>).

  //         </p>
  //         </Fade>
  //       </div>
  //       <div className='low-title'>
  //       <Fade bottom>
  //         <p className='low-titles'>
  //           7. Értelmező rendelkezések
  //         </p>
  //         <p className='low-title-content2'>
  //           &emsp;- <b>Személyes adat:</b> az Önnel kapcsolatba hozható adat, így például az Ön neve, email
  //           címe, arcképe, hangja, azonosító jele, valamint egy vagy több fizikai, &nbsp;&nbsp;&nbsp;&nbsp;fiziológiai,
  //           mentális gazdasági, kulturális vagy szociális azonosságára jellemző ismeret, valamint
  //           az ezekből levonható következtetés. <br />
  //           &emsp;- <b>Hozzájárulás:</b> az érintett akaratának önkéntes és határozott kinyilvánítása, amely
  //           megfelelő tájékoztatáson alapul és amellyel félreérthetetlen &nbsp;&nbsp;&nbsp;&nbsp;beleegyezését adja a rá
  //           vonatkozó személyes adatok – teljes körű vagy egyes műveletekre kiterjedő –
  //           kezeléséhez.<br />
  //           &emsp;- <b>Adatkezelő:</b> az a természetes vagy jogi személy (pl. korlátolt felelősségű társaság),
  //           illetve jogi személyiséggel nem rendelkező szervezet (pl. egyesület), &nbsp;&nbsp;&nbsp;&nbsp;amely
  //           meghatározza az adatok kezelésének célját, illetve meghozza és végrehajtja vagy
  //           végrehajtatja az adatkezelésre vonatkozó döntéseket.<br />
  //           &emsp;- <b>Adatkezelés:</b> az adatokon végzett művelet, így például a személyes adatok gyűjtése,
  //           felvétele, rögzítése, rendszerezése, tárolása, megváltoztatása, &nbsp;&nbsp;&nbsp;&nbsp;felhasználása,
  //           lekérdezése, továbbítása, törlése, megsemmisítése, kép- vagy hangfelvétel készítése stb.<br />
  //           &emsp;- <b>Adattovábbítás:</b> az adat meghatározott harmadik személy számára történő
  //           hozzáférhetővé tétele.<br />
  //           &emsp;- <b>Adatfeldolgozás:</b> az adatkezelési műveletekhez kapcsolódó technikai feladatok
  //           elvégzése, így például az adatok tárolásának biztosítása, könyvelési &nbsp;&nbsp;&nbsp;&nbsp;feladatok ellátása.<br />
  //           &emsp;- <b>Adattörlés:</b> az adatok felismerhetetlenné tétele oly módon, hogy a helyreállításuk többé
  //           nem lehetséges.

  //         </p>
  //         </Fade>
  //       </div>
  //       <div className='low-title'>
  //       <Fade bottom>
  //         <p className='low-titles'>
  //           8. Egyéb
  //         </p>
  //         <p className='low-titles2'>
  //           8.1. Jelen adatkezelési tájékoztató Siófok, 2023.06.22-én lép hatályba.
  //         </p>
  //         </Fade>
  //       </div>
  //     </motion.div>
  //   </AnimatePresence>
  // )
}
export default DataPolicy;
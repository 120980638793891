import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from './AuthProvider';
import { db } from './Firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Alert, ProgressBar } from 'react-bootstrap';

const Success = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [progress, setProgress] = useState(0);
    const [transactionId, setTransactionId] = useState(null);
    const [orderRef, setOrderRef] = useState(null);
    const hasRun = useRef(false);
  
    useEffect(() => {
      if(hasRun.current) return;
      hasRun.current = true;
      const startTime = Date.now();
      const duration = 15000;

      const confirmSubscription = async () => {
        const queryParams = new URLSearchParams(location.search);
        const uid = queryParams.get('uid');
        const sessionId = queryParams.get('session_id');
  
        if (uid && sessionId) {
          try {
            const subscriptionDoc = await getDoc(doc(db, 'subscriptions', uid));
            if (subscriptionDoc.exists()) {
              const subscriptionData = subscriptionDoc.data();
              setTransactionId(subscriptionData.transactionId);
              setOrderRef(subscriptionData.orderRef);

              const countdownInterval = setInterval(() => {
                const elapsed = Date.now() - startTime;
                const fraction = elapsed / duration;
                const percentage = fraction * 100;

                if (percentage >= 100) {
                  setProgress(100);
                  clearInterval(countdownInterval);
                } else {
                  setProgress(percentage);
                }
              }, 100);

              const timeoutId = setTimeout(async () => {
                await fetch(`https://us-central1-balaton-autosisklola-live.cloudfunctions.net/simplePayCallback`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ t: subscriptionData.transactionId, e: "SUCCESS", o: subscriptionData.orderRef }),
                });

                // console.log("simplePayCallback called successfully for UID:", uid);
                navigate('/live');
                window.location.reload();
              }, duration);

              return() => {
                clearInterval(countdownInterval);
                clearTimeout(timeoutId);
              };
          } else {
              console.error("No subscription found for UID:", uid);
          }
          } catch (error) {
            console.error('Error confirming subscription:', error);
          }
        }
      };
  
      confirmSubscription();

    }, [location, user, navigate]);
  
    return (
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <Alert variant="success" className="p-4">
              <h2 className="mb-3">Sikeres előfizetés</h2>
              <p>
              Köszönjük, hogy előfizetett! Az oldal automatikusan
              átirányít <strong>20 másodpercen belül</strong>.<br/>
              Kérjük, ne frissítse és ne zárja be az oldalt.
              </p>
              <ProgressBar now={progress} label={`${Math.round(progress)}%`} animated />
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  };
  
  export default Success;
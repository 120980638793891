import React from 'react';

const MyTable = ({ data }) => {
  let rowSpanCount = 1;

  const tableStyle = {
    border: '1px solid black',
    borderCollapse: 'collapse',
    width: '100%',
    textAlign: 'left',
  };

  const tdStyle = {
    border: '1px solid black',
    padding: '8px',
  };


  return (
    <table style={tableStyle}>
      <thead>
        <tr>
          <th style={tdStyle}>Képzési díjak</th>
          <th style={tdStyle}>A1</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          if (item.adatkezeles_celja !== null) {
            rowSpanCount = 1;
            while (
              data[index + rowSpanCount] &&
              data[index + rowSpanCount].adatkezeles_celja === null
            ) {
              rowSpanCount++;
            }
          }

          return (
            <tr key={item.szemelyes_adat}>
              <td style={tdStyle}>{item.szemelyes_adat}</td>
              {item.adatkezeles_celja !== null && (
                <td rowSpan={rowSpanCount} style={tdStyle}>{item.adatkezeles_celja}</td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default MyTable;
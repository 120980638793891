import React, { useState, useEffect } from 'react';
import './BlinkingButton.css';

function BlinkingButton() {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setInterval(() => {
            setVisible(prev => !prev);
        }, 500); // blink every 500ms

        return () => clearInterval(timer); // clean up on unmount
    }, []);


    return (
        <>
        <a href="https://etitan.hu/site/hu/WebReg/IskolaiRegisztracio?azonosito=hzXvSuWYrUC1_6_zjIBazg" target="_blank" rel="noopener noreferrer">
            <button className={`red-button`}>
                Kattints ide az online jelentkezéshez!
            </button>
        </a>
        </>
    );
}

export default BlinkingButton;